<template>
  <div>
    <div v-if="editor">
      <image-picker
        :modal-id="`addImage-${imagePickerId}`"
        @input="handleImageSelected"
      />
      <div class="m-n1">
        <b-button-group class="m-1">
          <b-button
            size="sm"
            :variant="editor.isActive('bold') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <b>bold</b>
          </b-button>
          <b-button
            size="sm"
            :variant="editor.isActive('italic') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleItalic().run()">
            <i>italic</i>
          </b-button>
          <b-button
            size="sm"
            :variant="editor.isActive('underline') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleUnderline().run()">
            <u>underline</u>
          </b-button>
          <b-button
            size="sm"
            :variant="editor.isActive('highlight') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleHighlight().run()"
          >
            <mark>highlight</mark>
          </b-button>
          <b-button
            size="sm"
            :variant="editor.isActive('strike') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <strike>strike</strike>
          </b-button>
        </b-button-group>

        <b-button-group class="m-1">
          <b-button
            v-for="n in 6"
            :key="`b-h${n}`"
            :variant="editor.isActive('heading', { level: n }) ? 'secondary' : 'outline-secondary'"
            size="sm"
            @click="editor.chain().focus().toggleHeading({ level: n }).run()"
          >
            h{{n}}
          </b-button>
        </b-button-group>
        <b-button-group class="m-1">
          <b-button
            size="sm"
            :variant="editor.isActive('bulletList') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            • list
          </b-button>
          <b-button
            size="sm"
            :variant="editor.isActive('orderedList') ? 'secondary' : 'outline-secondary'"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            1. list
          </b-button>
        </b-button-group>

        <b-button
          size="sm"
          class="m-1"
          :variant="editor.isActive('blockquote') ? 'secondary' : 'outline-secondary'"
          @click="editor.chain().focus().toggleBlockquote().run()"
        >
          “blockquote”
        </b-button>

        <b-button-group class="m-1">
          <b-button
            size="sm"
            :variant="editor.isActive('link') ? 'secondary' : 'outline-secondary'"
            @click="setLink"
          >
            Link
          </b-button>
          <b-button
            v-if="editor.isActive('link')"
            size="sm"
            @click="editor.chain().focus().unsetLink().run()"
          >
            remove
          </b-button>
        </b-button-group>

        <b-button
          class="m-1"
          variant="outline-secondary"
          size="sm"
          v-b-modal="`addImage-${imagePickerId}`"
        >
          Add image
        </b-button>

        <b-button
          class="m-1"
          variant="outline-secondary"
          size="sm"
          @click="addVideo"
        >
          Add video
        </b-button>
      </div>
    </div>
    <editor-content
      class="mt-3"
      :editor="editor"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Highlight from '@tiptap/extension-highlight';

import Heading from '@tiptap/extension-heading';

import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';

import Blockquote from '@tiptap/extension-blockquote';

import Image from '@tiptap/extension-image';
import Dropcursor from '@tiptap/extension-dropcursor';

import ImagePicker from '@/components/ImagePicker.vue';

import Link from '@tiptap/extension-link';

import Iframe from './iframe';

export default {
  components: {
    EditorContent,
    ImagePicker,
  },

  data() {
    return {
      imagePickerId: Date.now(),
      editor: null,
    };
  },

  props: {
    value: {
      required: true,
    },
  },

  watch: {
    value(content) {
      this.editor.commands.setContent(content);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,

        Bold,
        Italic,
        Underline,
        Strike,
        Highlight,

        Heading,

        BulletList,
        OrderedList,
        ListItem,

        Blockquote,

        Image.extend({
          addAttributes() {
            return {
              ...this.parent?.(),
              sizes: {
                default: null,
              },
              srcset: {
                default: null,
              },
            };
          },
        }),
        Dropcursor,

        Link,

        Iframe,
      ],
      content: this.value,
      onUpdate: ({ editor }) => {
        this.$emit('input', editor.getHTML());
      },
    });
  },

  methods: {
    setLink() {
      const url = window.prompt('URL');

      if (url) {
        this.editor.chain().focus().setLink({ href: url }).run();
      }
    },
    addVideo() {
      const url = window.prompt('URL');

      if (url) {
        this.editor.chain().focus().setIframe({ src: url }).run();
      }
    },
    handleImageSelected({
      baseUrl, id, alt, title,
    }) {
      this.editor.chain().focus().setImage({
        sizes: `(max-width: 576px) 576px,
                (max-width: 768px) 768px,
                (max-width: 992px) 992px,
                (max-width: 1200px) 1200px,
                1400px`,
        src: `${baseUrl}/f_auto,q_70,w_768/${id}`,
        srcset: `
          ${baseUrl}/f_auto,q_70,w_576/${id} 576w,
          ${baseUrl}/f_auto,q_70,w_768/${id} 768w,
          ${baseUrl}/f_auto,q_70,w_992/${id} 992w,
          ${baseUrl}/f_auto,q_70,w_1200/${id} 1200w,
          ${baseUrl}/f_auto,q_70,w_1400/${id} 1400w,
        `,
        alt,
        title,
      }).run();
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="stylus">
.ProseMirror
  display block
  width 100%
  padding 0.375rem 0.75rem
  color #495057
  background-color #fff
  background-clip padding-box
  border 1px solid #ced4da
  border-radius 0.25rem
  transition border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  img
    max-width 100%
    &.ProseMirror-selectednode
      outline 3px solid #68CEF8
  blockquote
    position relative
    background-color #F6F1EE
    font-size 18px
    box-sizing border-box
    padding 40px
    margin-top 40px
    line-height 1.4em
    &:before
      display block
      position absolute
      content '"'
      top -20px
      left 0
      font-weight bold
      font-size 96px
      line-height 1
.iframe-wrapper
  position: relative;
  padding-bottom (100/16*9)%;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;

  &.ProseMirror-selectednode
    outline 3px solid #68CEF8

  iframe
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
</style>
